.support-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.support-modal-content {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    width: 500px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
}

.support-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.support-modal-title {
    margin-bottom: 20px;
}

.support-form-group {
    margin-bottom: 20px;
    text-align: left;
}

.support-form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.support-form-group textarea,
.support-form-group input {
    width: calc(100% - 20px); /* Ensuring padding on the right side */
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
}

.support-send-button {
    padding: 10px 20px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}
