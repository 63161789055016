.login-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.login-modal-content {
    background-color: #fff;
    border-radius: 8px;
    width: 100%;
    max-width: 400px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-modal-header h2 {
    margin: 0;
    font-family: 'Billabong', sans-serif;
    font-size: 40px;
}

.login-form {
    width: 100%;
}

.login-form-group {
    margin-bottom: 15px;
}

.login-form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.login-button {
    width: 100%;
    padding: 10px;
    background-color: #3897f0;
    border: none;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
}

.login-button:hover {
    background-color: #318ce7;
}

.login-separator {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 20px 0;
}

.login-separator .line {
    flex-grow: 1;
    height: 1px;
    background-color: #ccc;
}

.login-separator .or {
    margin: 0 10px;
    color: #999;
    font-weight: bold;
}

.login-forgot-password a {
    color: #385185;
    text-decoration: none;
}

.login-forgot-password a:hover {
    text-decoration: underline;
}

.login-signup {
    margin-top: 20px;
    text-align: center;
}

.login-signup p {
    margin: 0;
}

.login-signup a {
    color: #3897f0;
    text-decoration: none;
}

.login-signup a:hover {
    text-decoration: underline;
}

.login-error {
    color: red;
    font-size: 14px;
    margin-bottom: 10px;
    text-align: center;
}
