.signup-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.signup-modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-width: 90%;
}

.signup-modal-header {
    text-align: center;
    margin-bottom: 20px;
}

.signup-form {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align the form fields */
    padding: 0 10px; /* Ensure padding on both sides of the form */
}

.signup-form-group {
    margin-bottom: 15px;
    width: 100%; /* Ensure the input takes the full width of the parent */
}

.signup-form-group input {
    width: 100%;
    max-width: 320px; /* Set a fixed maximum width */
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.signup-button {
    background: #3897f0;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 15px;
    width: 100%;
    max-width: 320px; /* Set the same fixed maximum width */
}

.signup-error {
    color: red;
    text-align: center;
    margin-bottom: 10px;
    width: 100%;
    max-width: 320px; /* Set the same fixed maximum width */
}

.signup-separator {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 20px 0;
}

.signup-separator .line {
    flex-grow: 1;
    height: 1px;
    background-color: #ccc;
}

.signup-separator .or {
    margin: 0 10px;
    color: #999;
    font-weight: bold;
}

.signup-login {
    margin-top: 20px;
    text-align: center;
}

.signup-login p {
    margin: 0;
}

.signup-login a {
    color: #3897f0;
    text-decoration: none;
}

.signup-login a:hover {
    text-decoration: underline;
}

.signup-error {
    color: red;
    font-size: 14px;
    margin-bottom: 10px;
    text-align: center;
}
