/* src/App.css */

/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');

.App {
    text-align: center;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #fff; /* White background for header */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo-container {
    display: flex;
    align-items: center;
}

.logo {
    font-family: 'Lobster', cursive;
    font-weight: bold;
}

.spank {
    color: #FF69B4; /* Pink color */
    font-size: 4rem; /* Increase the size of "PINK" */
}

.wire {
    color: #1E90FF; /* Blue color */
    font-size: 3rem;
    margin-left: 5px; /* Adjust spacing between words */
}

.search-bar {
    flex-grow: 1;
    margin: 0 20px;
    max-width: 70%; /* Adjust max-width to provide more space */
}

.search-bar input {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.user-icon {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #007BFF; /* Different color for visibility */
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-icon .user-initial {
    color: #fff;
    font-weight: bold;
}

.sign-in-button {
    display: flex;
    align-items: center;
    border: 1px solid #007BFF;
    border-radius: 20px;
    padding: 5px 10px;
    cursor: pointer;
    margin-left: 20px; /* Add margin to provide space between search bar and sign-in button */
}

.sign-in-text {
    color: #007BFF;
    font-weight: bold;
    margin-left: 5px;
}

.dropdown-menu {
    position: absolute;
    top: 50px;
    right: 0;
    width: 200px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Drop shadow for cool effect */
    border-radius: 8px;
    overflow: hidden;
    z-index: 1000;
}

.dropdown-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.dropdown-menu li {
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.dropdown-menu li:hover {
    background-color: #f1f1f1;
}

.tab-navigation-container {
    margin-top: 10px;
    background-color: #000;
    padding: 10px 0;
    width: 100%; /* Full width */
    margin: 0 auto;
}

.tab-navigation {
    display: flex;
    justify-content: space-around;
    list-style: none;
    padding: 0;
}

.tab-navigation li {
    padding: 10px 20px;
    cursor: pointer;
    color: white;
    font-weight: bold;
    font-family: 'Roboto', sans-serif; /* Use Roboto font */
    transition: color 0.3s ease, border-bottom 0.3s ease;
}

.tab-navigation li.selected {
    border-bottom: 3px solid #1E90FF; /* Lighter blue color */
    color: #ADD8E6; /* Very light blue color for the text */
    font-weight: bolder; /* Thicker font */
}

.tab-navigation li:hover {
    color: #1E90FF; /* Change color to blue on hover */
    border-bottom: 3px solid #1E90FF; /* Show underline on hover */
}

.banner-ad-top {
    width: calc(100% - 200px); /* Subtract the widths of the banner ads */
    background-color: #f1f1f1;
    text-align: center;
    padding: 20px;
    margin: 10px auto 0 auto;
}

.video-grid-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    padding: 20px;
}

.banner-ad {
    width: 160px;
    background-color: #f1f1f1;
    text-align: center;
    padding: 20px;
    border-radius: 8px;
    flex-shrink: 0;
    height: 100vh; /* Full viewport height */
    position: sticky;
    top: 0;
}

.video-grid-wrapper {
    flex-grow: 1;
}

.video-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 20px;
    max-width: 100%;
    margin: 0 auto;
}

.no-content {
    color: white;
    padding: 20px;
    font-size: 18px;
}


.login-modal-content input,
.login-modal-content button {
    width: calc(100% - 40px); /* Ensure input fields and buttons are the same width */
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

/* App.css */
/* Add the following to App.css for the footer */
.footer {
    text-align: center;
    padding: 20px;
}

.support-button {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
}


.support-button:hover {
    background-color: #0056b3;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo-container {
    display: flex;
    align-items: center;
}

.logo {
    font-size: 24px;
    font-weight: bold;
}

.search-bar {
    flex-grow: 1;
    margin: 0 20px;
    display: flex;
}

.search-bar input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.upload-button {
    display: flex;
    align-items: center;
    background-color: #ff0000;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}

.upload-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.footer {
    padding: 20px;
    text-align: center;
}

.support-button {
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.support-button-fixed {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.support-button-fixed:hover {
    background-color: #0056b3;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    flex-wrap: wrap; /* Allow the header elements to wrap on smaller screens */
}

.logo-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px; /* Space below for wrapping */
}

.logo {
    font-family: 'Lobster', cursive;
    font-weight: bold;
}

.spank {
    color: #FF69B4; /* Pink color */
    font-size: 3rem; /* Adjust the size to better fit mobile screens */
}

.wire {
    color: #1E90FF; /* Blue color */
    font-size: 2rem;
    margin-left: 5px;
}

.search-bar {
    flex-grow: 1;
    margin: 0 10px; /* Reduce margins for better fit on mobile */
    max-width: 100%; /* Ensure it doesn't exceed the screen width */
    order: 2; /* Place the search bar after the logo on mobile */
}

.search-bar input {
    width: 100%;
    padding: 8px; /* Adjust padding for better mobile fit */
    border-radius: 4px;
    border: 1px solid #ccc;
}

.user-icon {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #007BFF;
    color: white;
    justify-content: center;
    order: 3; /* Place user icon last on mobile */
    margin-left: auto; /* Push it to the right */
}

.sign-in-button {
    display: flex;
    align-items: center;
    border: 1px solid #007BFF;
    border-radius: 20px;
    padding: 5px 10px;
    cursor: pointer;
    margin-left: auto;
    order: 3; /* Place sign-in button last on mobile */
}
