/* src/components/MobileVideoGrid.css */
.mobile-video-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2px;
    padding: 2px;
}

.mobile-video-card {
    position: relative;
    width: 100%;
    padding-top: 100%;
}

.mobile-video-thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
