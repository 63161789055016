/* src/components/VideoGrid.css */

.video-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 20px;
    padding: 20px;
}

.thumbnail-placeholder {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    position: relative;
    background-color: #ccc;
    border-radius: 8px;
    overflow: hidden;
}

.thumbnail-placeholder img {
    width: 100%;
    height: auto;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

.video-thumbnail {
    width: 100%;
    height: auto;
    border-radius: 8px;
    position: absolute;
    top: 0;
    left: 0;
}

.video-card {
    position: relative;
}

.video-duration {
    position: absolute;
    bottom: 8px;
    right: 8px;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 12px;
}

.play-button-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    color: #fff;
    font-size: 18px;
}

.hover-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    opacity: 0;
    transition: opacity 0.3s;
}

.video-card:hover .hover-overlay {
    opacity: 1;
}
