.mobile-video-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.video-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
}

.user-info {
    display: flex;
    align-items: center;
}

.profile-image-circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #007bff; /* Customize the background color as needed */
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    margin-right: 10px;
}

.user-details {
    display: flex;
    flex-direction: column;
}

.username {
    font-weight: bold;
    font-size: 16px;
}

.video-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

video {
    width: 100%;
    max-width: 600px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.video-interactions {
    width: 100%;
    text-align: left;
}

.icons-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.icon {
    font-size: 24px;
    cursor: pointer;
}

.icon.liked {
    color: red;
}

.icon.favorited {
    color: gold;
}

/* Separate likes and comments */
.likes {
    font-size: 14px;
    margin-bottom: 2px;
}

.comments {
    font-size: 14px;
    margin-bottom: 10px;
    color: #777;
}

.video-date {
    font-size: 12px;
    color: #777;
}
