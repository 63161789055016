/* src/components/VideoCard.css */

.video-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: relative;
}

.video-thumbnail {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    object-fit: cover; /* Ensure the thumbnail is not stretched */
    position: absolute;
    top: 0;
    left: 0;
}

.thumbnail-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    background-color: #f1f1f1;
}

.thumbnail-placeholder {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-duration {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 2px 5px;
    border-radius: 4px;
    font-size: 12px;
}

.play-button-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    font-size: 20px;
}

.hover-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px; /* Increase font size */
    font-weight: bold; /* Make font bold */
    opacity: 1;
    transition: opacity 0.3s;
}

.hover-overlay span {
    position: relative;
    text-align: center;
}
