.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    display: flex;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden; /* This ensures child elements don't overflow the rounded corners */
    width: 95%;
    height: 95vh;
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.left-banner-ad {
    width: 200px;
    background-color: #f1f1f1;
    text-align: center;
    padding: 20px;
    border-radius: 8px;
    flex-shrink: 0;
}

.main-content {
    display: flex;
    flex: 1;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.video-section {
    position: relative;
    overflow: visible; /* Allow preview to overflow */
    flex: 3;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0; /* Explicitly remove border radius */
}

.video-section video {
    width: 100%;
    height: auto;
    object-fit: contain;
    max-width: none;
    max-height: 100%;
    border-radius: 0; /* Explicitly remove border radius */
}

.preview-image {
    position: absolute;
    pointer-events: none;
    background: rgba(0, 0, 0, 0.8);
    padding: 4px;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    /* Prevent preview from being cut off */
    min-width: 160px;
    max-width: 160px;
}

.preview-time {
    color: white;
    text-align: center;
    margin-top: 4px;
    font-size: 12px;
}

.details-section {
    flex: 1.5; /* Increased from 1 to make right panel wider */
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
}

.video-info {
    margin-bottom: 20px;
}

.comments-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    overflow-y: auto;
    margin-top: 20px;
}

.no-comments-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-grow: 1;
}

.no-comments {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.comment {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
}

.comment-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #007BFF;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-right: 10px;
}

.comment-content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.comment-text {
    display: block;
    margin-bottom: 5px;
}

.comment-details {
    display: flex;
    gap: 10px;
    font-size: 12px;
    color: #555;
}

.comment-like-icon {
    cursor: pointer;
    align-self: flex-start;
    margin-left: auto;
    margin-top: 2px;
}

.comment-like-icon.liked {
    color: red;
}

.actions-section {
    display: flex;
    justify-content: space-around;
    border-top: 1px solid #ddd;
    padding: 10px 0;
}

.icon {
    font-size: 24px;
    cursor: pointer;
}

.add-comment-section {
    border-top: 1px solid #ddd;
    padding: 10px 0;
    display: flex;
    align-items: center;
}

.add-comment-section input {
    flex-grow: 1;
    padding: 10px;
    border: none;
    outline: none;
}

.emoji-icon {
    margin-right: 10px;
    font-size: 24px;
    cursor: pointer;
}

.post-button {
    color: #3897f0;
    cursor: pointer;
    margin-left: 10px;
}

.login-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.login-modal-content {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    width: 350px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.login-modal-content h2 {
    margin-bottom: 20px;
}

.login-modal-content input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.login-modal-content button {
    width: 100%;
    padding: 10px;
    background-color: #3897f0;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.login-modal-content .error {
    color: red;
    margin-bottom: 10px;
}

.login-modal-content p {
    margin-top: 10px;
    color: #999;
}

.icon.liked {
    color: red;
}

.icon.favorited {
    color: blue;
}

/* VideoModal.css */
.left-banner-ad {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 10px;
}

.keyword-section {
    padding: 20px;
    height: 25%; /* Limit to 25% of the height */
    overflow-y: auto; /* Enable scrolling if necessary */
    margin-top: auto; /* Push it to the bottom */
}

.keywords {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.keyword-tag {
    background-color: #ffffff;
    border: 1px solid #000000;
    border-radius: 10px;
    padding: 5px 10px;
    font-size: 0.9em;
    color: #000000;
    display: inline-block;
}
