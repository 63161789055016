.tab-navigation-container {
    overflow-x: auto; /* Enable horizontal scrolling */
    -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
}

.tab-navigation {
    display: flex;
    justify-content: space-between; /* Ensure tabs spread evenly */
    list-style: none;
    padding: 0;
    margin: 0;
    white-space: nowrap; /* Prevent tabs from wrapping */
}

.tab-navigation li {
    padding: 10px 20px;
    cursor: pointer;
    color: white;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    flex-shrink: 0; /* Prevent tabs from shrinking */
}

.tab-navigation li.selected {
    border-bottom: 3px solid #1E90FF;
    color: #ADD8E6;
    font-weight: bolder;
}

.tab-navigation li:hover {
    color: #1E90FF;
    border-bottom: 3px solid #1E90FF;
}

.home-icon {
    width: 24px;
    height: 24px;
}
